import { React, useState } from 'react'
import './Header.css'
import { NavLink } from 'react-router-dom'
import { GiHamburgerMenu } from 'react-icons/gi'
import logoImage from '../images/Salestech.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume, faAngleDown, faAngleRight, faXmark,faAngleUp } from '@fortawesome/free-solid-svg-icons'
const Header = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const handleCloseForm = () => {
    setShowForm(false);
  };
  const handleOpenForm = () => {
    setShowForm(true);
  }
 
  const [subMenuState, setSubMenuState] = useState({
    solar: false,
    marketing: false,
    lead:false,
    brand:false,
    ecommerce:false
  });

  const toggleSubMenu = (service) => {
    setSubMenuState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = key === service ? !prevState[key] : false;
        return acc;
      }, {}),
    }));
  };
  return (
    <div>
      <div className='header'>
        <div className="main-navbar-header">
          <div className="logo-container-header">
            <img src={logoImage} alt="logo image" className='logo-image-header' />
          </div>
          <div className='navbar-text'>
            <h3 className='navbar-text-header'>Need an Expert technical Team? <span className='navbar-number-menu'>484-877-7850</span> </h3>
            <div className="freequote-button">
              <h3 className='freequote-button-text' onClick={handleOpenForm}>GET A MARKETING</h3>
            </div>
          </div>
        </div>
        <div className='additional-header'>
          <div className="additional-navbar-number-menu-container">
            <FontAwesomeIcon className='phone-icon-font-awesome' icon={faPhoneVolume} />
          </div>
          <div onClick={handleOpenForm} className="additional-navbar-freequote-button-container">
            <span className='additional-navbar-freequote-button' >GET A MARKETING</span>
          </div>
        </div>
        <div className="main-navbar-options">
          <div className={showMediaIcons ? "navbar-options mobile-navbar-options" : "navbar-options"} id='mobileMenu'>
            <ul>
              <li>
                <NavLink to="/home">HOME</NavLink>
              </li>
              <li className="navbar-services-option">
                <NavLink className="services-option-main" to="/services">SERVICES &nbsp;
                  <FontAwesomeIcon className='fa-Angle-Down' icon={faAngleDown} />
                  <FontAwesomeIcon className='fa-Angle-Right' icon={faAngleRight} />
                </NavLink>
                <div></div>
                <div className='sub-menu mobile-sub-menu'>
                  <li onClick={() => toggleSubMenu('solar')}>
                    Solar Services{' '}
                    <FontAwesomeIcon
                      className={`fa-Angle-icon ${subMenuState.solar ? 'open' : ''}`}
                      icon={subMenuState.solar ? faAngleUp:faAngleDown }
                    />
                  </li>
                  {subMenuState.solar && (
                    <div className='sub-menu-subservices'>
                      <li>Solar Roof Mounts</li>
                      <li>Solar Ground Mounts</li>
                      <li>Solar Affiliate</li>
                    </div>
                  )}

                  <li onClick={() => toggleSubMenu('marketing')}>
                    Marketing Services{' '}
                    <FontAwesomeIcon
                      className={`fa-Angle-icon ${subMenuState.marketing ? 'open' : ''}`}
                      icon={subMenuState.marketing ? faAngleUp:faAngleDown }
                    />
                  </li>
                  {subMenuState.marketing && (
                    <div className='sub-menu-subservices'>
                      <li>B2B Marketing</li>
                      <li>B2C Marketing</li>
                      <li>Media Content Management</li>
                      <li>Digital Marketing</li>
                    </div>
                  )}
                    <li onClick={() => toggleSubMenu('lead')}>
                    Lead generation{' '}
                    <FontAwesomeIcon
                      className={`fa-Angle-icon ${subMenuState.lead ? 'open' : ''}`}
                      icon={subMenuState.lead ? faAngleUp:faAngleDown }
                    />
                  </li>
                  {subMenuState.lead && (
                    <div className='sub-menu-subservices'>
                      <li>Lead Generation</li>
                      <li>Direct Custom Mailers</li>
                      <li>Content Writing</li>
                      <li>Graphic Design</li>
                      <li>Search Engine Optimization</li>
                      <li></li>
                    </div>
                  )}
                    <li onClick={() => toggleSubMenu('brand')}>
                    Brand and Design Services{' '}
                    <FontAwesomeIcon
                      className={`fa-Angle-icon ${subMenuState.brand ? 'open' : ''}`}
                      icon={subMenuState.brand ? faAngleUp:faAngleDown }
                    />
                  </li>
                  {subMenuState.brand && (
                    <div className='sub-menu-subservices'>
                      <li>Brand Identity</li>
                      <li>Logo Design</li>
                      <li>Wireframing</li>
                      <li>UI/UX Design</li>
                    </div>
                  )}
                    <li onClick={() => toggleSubMenu('ecommerce')}>
                    Web Services{' '}
                    <FontAwesomeIcon
                      className={`fa-Angle-icon ${subMenuState.ecommerce ? 'open' : ''}`}
                      icon={subMenuState.ecommerce ? faAngleUp:faAngleDown }
                    />
                  </li>
                  {subMenuState.ecommerce && (
                    <div className='sub-menu-subservices'>
                      <li>E-commerce Solutions</li>
                      <li>CRM Integrations</li>
                      <li>Web Design</li>
                    </div>
                  )}
                </div>
              </li>
              <li>
                <NavLink to="/about">ABOUT US</NavLink>
              </li>
              <li>
                <NavLink to="/contact">CONTACT US</NavLink>
              </li>
            </ul>
          </div>
          <div className="hamburger-menu" >
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu className='hamberger-menu-icon' />
            </a>
          </div>
          <div className="new-logo-container-header">
            <img src={logoImage} alt="logo image" className='new-logo-image-header' />
          </div>
        </div>


      </div>
      <div className="pop-up-form-container" style={{ display: showForm ? 'block' : 'none' }}>
        <div className='pop-up-form-main-div'>
          <FontAwesomeIcon className='cross-icon' icon={faXmark} color='red' onClick={handleCloseForm} />

          <div className="pop-up-form-heading-container">
            <h1>Request a Project Estimation</h1>

          </div>
          <div className="pop-up-form">

            <form action="">
              <label htmlFor="label">Name*</label>
              <input className='name-text-pop-up-form' type="text" required placeholder='Name' />
              <label htmlFor="label"> Phone or Email*</label>
              <input className='email-text-pop-up-form' type="email" required placeholder='Email' />
              <label htmlFor="label">Project Details*</label>
              <textarea className='details-text-pop-up-form' type="text" required placeholder='project Details' />
              <button className='pop-up-form-submit-button'>Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header