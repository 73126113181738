import React from 'react';
import styles from './Index.module.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import webDevelopment from '../../images/top11.png';
import solarRoofMount from '../../images/solarRoof (1).jpg';
import marketing from '../../images/marketing (1).jpg';
import graphicDesign from '../../images/graphicDesign.avif.jpg';
import logoDesign from '../../images/logoDesign.avif.jpg';
import uxDesign from '../../images/uxDesign (1).jpg';
import seo from '../../images/seo (1).jpg';

const Index = () => {
  const images = [
    {
      src: webDevelopment,
      alt: 'web development',
      caption: 'Web Development',
      buttonText: 'Learn More',
      buttonLink: 'https://example.com',
    },
    {
      src: solarRoofMount,
      alt: 'solar Roof',
      caption: 'Solar Roof',
      buttonText: 'Learn More',
      buttonLink: 'https://example.com',
    },
    {
      src: graphicDesign,
      alt: 'Digital Marketing',
      caption: 'Graphic Designing',
      buttonText: 'Learn More',
      buttonLink: 'https://example.com',
    },
    {
      src: logoDesign,
      alt: 'Digital Marketing',
      caption: 'Logo Designing',
      buttonText: 'Learn More',
      buttonLink: 'https://example.com',
    },
    {
      src: seo,
      alt: 'Digital Marketing',
      caption: 'Seo Optimization',
      buttonText: 'Learn More',
      buttonLink: 'https://example.com',
    },
    {
      src: uxDesign,
      alt: 'Ux Design',
      caption: 'UI/UX Design',
      buttonText: 'Learn More',
      buttonLink: 'https://example.com',
    },
    {
      src: marketing,
      alt: 'Digital Marketing',
      caption: 'Marketing',
      buttonText: 'Learn More',
      buttonLink: 'https://example.com',
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={styles.mainContainer}>
    <h1>Our Services</h1>
    <Carousel
      infinite={true}
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={3000}
      className={styles.carousel}
    >
      {images.map((image, index) => (
        <div key={index} className={styles.carouselItem}>
          {/* Overlay */}
          <div className={styles.overlay}></div>
          
          {/* Image */}
          <img src={image.src} alt={image.alt} />
  
          {/* Caption and Button */}
          <div className={styles.caption}>
            <p>{image.caption}</p>
            <a href={image.buttonLink} target="_blank" rel="noopener noreferrer">
              {image.buttonText}
            </a>
          </div>
        </div>
      ))}
    </Carousel>
  </div>
  );
};

export default Index;
