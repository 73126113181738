import React from 'react'
import './DigitalMarketing.css'
import seoImage from '../images/seo.jpg'
import socialMediaMarketing from '../images/socialMedia.avif'
import contentMarketingPicture from '../images/contentMarketing.jpg'
const DigitalMarketing = () => {
  return (
    <div className='articles-section-main'>
    <div className="articles-heading">
      <h1 className='articles-heading-text'>Digital Marketing Articles</h1>
    </div>
      <div className="articles-cards">
          <div className="articles-other-cards">
             <div className="articles-other-cards-1" style={{ backgroundImage: `url(${seoImage})` }}>
              <div className="overlay-1">
              <h3 className='articles-other-cards-heading-text'>Search engine optimization</h3>
              <p className="articles-other-cards-heading-text-body">
              SEO involves optimizing websites to improve their visibility in search engine results pages. The goal is to increase organic (non-paid) traffic to a website by improving its search engine rankings.
              </p>
              </div>
             </div>
           
          <div className="articles-other-cards-2" style={{ backgroundImage: `url(${socialMediaMarketing})` }}>
              <div className="overlay-2">
              <h3 className='articles-other-cards-heading-text'>Social Media Marketing (SMM)</h3>
              <p className="articles-other-cards-heading-text-body">
              SMM focuses on promoting products or services through social media platforms like Facebook, Instagram, Twitter, LinkedIn, and others. It involves creating engaging content, managing social media profiles, running paid ad campaigns, and building brand awareness.
              </p>
              </div>
          </div>
          <div className="articles-other-cards-3 " style={{ backgroundImage: `url(${contentMarketingPicture})` }}>
              <div className="overlay-3">
              <h3 className='articles-other-cards-heading-text'>Content Marketting</h3>
              <p className="articles-other-cards-heading-text-body">
              Content marketing focuses on creating and distributing valuable and relevant content to attract and engage a target audience. This includes blog posts, articles, videos, infographics, e-books, and more. The goal is to drive brand awareness, generate leads, and build relationships with customers.
              </p>
              </div>
          </div>
          </div>
      </div>
      <hr />
    </div>
  )
}

export default DigitalMarketing