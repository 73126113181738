import React from 'react'
import './AboutpageIntroduction.css'
import companypeople from '../images/uniquepicture.jpg'

const AboutpageIntroduction = () => {
  return (
    <div>
    <div className='about-page-main-container'>
        <div className='about-page-heading-container'>
            <h1>Get To Know Us. Work with Us.</h1>
        </div>
        <div className='about-page-banner-image'>
         <img className='about-banner-image-1' src={companypeople}></img>
        </div>
        
    </div>
    </div>
  )
}

export default AboutpageIntroduction