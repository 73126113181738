import React from 'react'
import './ServicesBanner.css'
import DigitalMarketingPicture from '../images/digitalMarketing.jpeg'
const ServicesBanner = () => {
  return (
    <div>
     <div className='services-banner-main-container' style={{ backgroundImage: `url(${DigitalMarketingPicture})` }}>
        <div className='services-banner-heading'>
        <div className='services-banner-Heading-text'>
            <h1>We Unlock B2B Growth: Sales Development & Digital Marketing Solutions Tailored to your Business</h1>
        </div>
        <div className='services-banner-text'>
            <p>Empower Your Sales Team with a Strategic Partnership that Delivers High-Quality Leads Through Comprehensive Inbound & Outbound Strategies</p>
        </div>
         <div className='services-banner-button-container'>
            <button className='services-banner-button'>Request a Proposal</button>
         </div>
        </div>
     </div>
    </div>
  )
}

export default ServicesBanner