import React from 'react'
import './Articles.css'
import webDevelopment from '../images/web-development.png'
import mobiledevelopment from '../images/mobile-app.jpg'
import desktopDevelopment from '../images/desktop.png'
const Articles = () => {
  return (
    <div className='articles-section-main'>
      <div className="articles-heading">
        <h1 className='articles-heading-text'>Popular Development Articles</h1>
      </div>
        <div className="articles-cards">
            <div className="articles-other-cards">
               <div className="articles-other-cards-1" style={{ backgroundImage: `url(${webDevelopment})` }}>
                <div className="overlay-1">
                <h3 className='articles-other-cards-heading-text'>Web App Development</h3>
                <p className="articles-other-cards-heading-text-body">
                This involves building applications or websites that are accessed through web browsers. It typically includes front-end development (HTML, CSS, JavaScript) and back-end development (server-side programming, databases, APIs).
                </p>
                </div>
               </div>
             
            <div className="articles-other-cards-2" style={{ backgroundImage: `url(${mobiledevelopment})` }}>
                <div className="overlay-2">
                <h3 className='articles-other-cards-heading-text'>Mobile App Development</h3>
                <p className="articles-other-cards-heading-text-body">
                Mobile development focuses on creating applications for mobile devices such as smartphones and tablets. It can involve native app development (using platform-specific languages like Swift for iOS or Java/Kotlin for Android) or cross-platform development frameworks like React Native or Flutter.
                </p>
                </div>
            </div>
            <div className="articles-other-cards-3 " style={{ backgroundImage: `url(${desktopDevelopment})` }}>
                <div className="overlay-3">
                <h3 className='articles-other-cards-heading-text'>Desktop development</h3>
                <p className="articles-other-cards-heading-text-body">
                This type of development involves building applications that run on desktop or laptop computers. It often involves languages and frameworks specific to the operating system such as C# and .NET for Windows or Objective-C and Cocoa for macOS.
                </p>
                </div>
            </div>
            </div>
        </div>
        <hr />
      </div>
  )
}

export default Articles