import React from 'react'
import './AboutuspageCard.css'
import uniquepicturecard from '../images/uniquepicture.webp'
const AboutuspageCard = () => {
  return (
    <div className='main-container-about-us-card'>
        <div className='about-us-page-left-half-container'>
          <div className='about-us-page-image'>
            <img src={uniquepicturecard}>
            </img>
          </div>
        </div>
        <div className='about-us-page-right-half-container'>
             <div className='about-us-page-right-half-heading-container'>
              <h1>Our unique approach and proven processes deliver best-in-class results</h1>
             </div>
             <div className='about-us-page-right-half-text-container'>
                <p>Led by a team of seasoned sales executives, our team has deep experience at Fortune 100 companies as well as smaller emerging enterprises. We are experts in the art and science of inside sales with an emphasis in prospecting.</p>
             </div>
        </div>
        
    </div>
  )
}

export default AboutuspageCard