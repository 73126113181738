import React from 'react'
import './AboutAwards.css'
import aboutusimage from '../images/pic1.jpg'
const AboutAwards = () => {
  return (
    <div className='main-container'>
      <div className='who-we-are-section'>
        <div className='who-we-are-heading'>
          <h2>Who We Are</h2>
        </div>
          <div className='who-we-are-text'>
             <p>We work hand-in-hand with our business-to-business clients to optimize their sales and marketing efforts by providing high quality sales development and outsourced insides sales programs.</p>
          </div>
      </div>
      <div className='image-section'>
        <div className='about-us-image-container'>
            <img className='about-us-image' src={aboutusimage}></img>
        </div>
      </div>
    </div>
  )
}

export default AboutAwards