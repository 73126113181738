import React from 'react'
import './BannerVideo.css'
import Video from '../images/bannerVideoHome.mp4'
import { Cursor, useTypewriter } from 'react-simple-typewriter'
const BannerVideo = () => {
  const [text,count] = useTypewriter({
    words:["Speciality with B2B, B2C Sales Leads","Outsourcing of CSR’S, SDR’S, Marketing & Tech Services",],
    loop:true,
    delaySpeed:2000,

 })
  return (
    <div>
      <div className="heading-overlay">
        <h2>WE ARE PROVIDING</h2>
        <h1> <span>{text}</span>
        <Cursor cursorColor='#F7AB0A'/></h1>
      </div>
      <div className="video-container">
        <video className='main-video' src={Video} autoPlay muted loop />
      </div>
    </div>
  )
}

export default BannerVideo