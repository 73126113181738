import React from 'react'
import './About.css'
import Header from './Header'
import Footer from './Footer'
import AboutAwards from './AboutAwards'
import AboutuspageCard from './AboutuspageCard'
import AboutuspageCar2 from './AboutuspageCar2'
import AboutpageIntroduction from './AboutpageIntroduction'
const About = () => {
  return (
    <div>
      <Header/>
      <AboutpageIntroduction/>
      <AboutAwards/>
      <AboutuspageCard/>
      <AboutuspageCar2/>
      <Footer/>
    </div>
  )
}

export default About