import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ServicesBanner from './ServicesBanner'
import ServicePageIntro from './ServicePageIntro'
import NewServicePageServices from './NewServicePageServices'
const NewServicesPage = () => {
  return (
    <div>
     <Header/>
     <ServicesBanner/>
     <ServicePageIntro/>
     <NewServicePageServices/>
     <Footer/>
    </div>
  )
}

export default NewServicesPage