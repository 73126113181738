import React from 'react'
import digitalmarketingImage from  '../images/digitalmarketing1st.png'
import softwareDevelopmentImage from '../images/softwaredevelopment.png'
import './NewServicePageServices.css'
const NewServicePageServices = () => {
  return (
    <div>
      <div className='new-service-page-services-main-container'>
        <div className='new-service-page-services-main-card'>
        <div className='new-service-page-services-main-card-logo-container'>
         <img className='new-service-page-services-main-card-logo' src={softwareDevelopmentImage}></img>
         </div>
         <div className='new-service-page-services-main-card-content'>
         <div className='new-service-page-services-main-card-heading-container'>
            <h3>Development Services</h3>
         </div>
         <div className='new-service-page-services-main-card-text-container'>
            <p>At Pro Sales Tech, we provide comprehensive development services tailored to meet your specific needs. Our team of skilled developers is dedicated to delivering high-quality solutions that empower businesses and drive growth. Whether you require web development, mobile app development, or custom software development, we have the expertise and experience to bring your ideas to life.</p>
         </div>
         </div>
         
        </div>
        <div className='new-service-page-services-card-container'>
         <div className='new-service-page-services-card1'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Digital Marketing</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card2'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Web Development</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card3'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Mobile App Development</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card4'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>SEO optimization</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
        </div>
        <div className='new-service-page-services-main-card'>
        <div className='new-service-page-services-main-card-logo-container'>
         <img className='new-service-page-services-main-card-logo' src={softwareDevelopmentImage}></img>
         </div>
         <div className='new-service-page-services-main-card-content'>
         <div className='new-service-page-services-main-card-heading-container'>
            <h3>Development Services</h3>
         </div>
         <div className='new-service-page-services-main-card-text-container'>
            <p>At Pro Sales Tech, we provide comprehensive development services tailored to meet your specific needs. Our team of skilled developers is dedicated to delivering high-quality solutions that empower businesses and drive growth. Whether you require web development, mobile app development, or custom software development, we have the expertise and experience to bring your ideas to life.</p>
         </div>
         </div>
         
        </div>
        <div className='new-service-page-services-card-container'>
         <div className='new-service-page-services-card1'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Digital Marketing</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card2'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Web Development</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card3'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Mobile App Development</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card4'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>SEO optimization</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
        </div>
        <div className='new-service-page-services-main-card'>
        <div className='new-service-page-services-main-card-logo-container'>
         <img className='new-service-page-services-main-card-logo' src={softwareDevelopmentImage}></img>
         </div>
         <div className='new-service-page-services-main-card-content'>
         <div className='new-service-page-services-main-card-heading-container'>
            <h3>Development Services</h3>
         </div>
         <div className='new-service-page-services-main-card-text-container'>
            <p>At Pro Sales Tech, we provide comprehensive development services tailored to meet your specific needs. Our team of skilled developers is dedicated to delivering high-quality solutions that empower businesses and drive growth. Whether you require web development, mobile app development, or custom software development, we have the expertise and experience to bring your ideas to life.</p>
         </div>
         </div>
         
        </div>
        <div className='new-service-page-services-card-container'>
         <div className='new-service-page-services-card1'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Digital Marketing</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card2'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Web Development</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card3'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>Mobile App Development</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
         <div className='new-service-page-services-card4'>
         <div className='new-service-page-services-card1-logo-container'>
         <img className='new-service-page-services-card1-logo' src={digitalmarketingImage}></img>
         </div>
         <div className='new-service-page-services-card1-heading-container'>
            <h3>SEO optimization</h3>
         </div>
         <div className='new-service-page-services-card1-text-container'>
            <p>In today's digital age, effective marketing strategies are crucial for businesses to thrive and reach their target audience.</p>
         </div>
         </div>
        </div>
      </div>
    </div>
  )
}

export default NewServicePageServices