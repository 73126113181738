import React from 'react'
import './Footer.css'
import logoImage from '../images/Salestech.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faYoutube,faTwitter,faInstagram,faFacebook} from '@fortawesome/free-brands-svg-icons'
const Footer = () => {
  return (
    <>
        <div className="footer-main-container">
            <div className="sign-up-container">
               <div className="heading-container">
                <h2>Sign Up For Industry News</h2>
               </div>
               <div className="sign-up-text">
                <p>Recieve the latest updates and industry news</p>
               </div>
               <div className="text-box-container">
                <form action="">
                    <input className='input-field-Footer' type="text" placeholder='Enter your email address'/>
                    <button className='submit-button'>Submit</button>
                </form>
               </div>
            </div>
            <div className="sign-up-container">
               <div className="heading-container">
                <h2>Contact Us For expert Advice</h2>
               </div>
               <div className="sign-up-text">
                <p>Call 484-877-7850 or click a button below to contact our team</p>
               </div>
               <div className="text-box-container">
                <form action="">
                    <button className='request-an-estimate'>Request an Estimate</button>
                    <button className='customer-support'>Contact customer support</button>
                </form>
               </div>
            </div>
            <div className="Navbar-Seo-Services">
               <div className="Heading-Container-footer1">
                <h3>Seo Services</h3>
                <h3>Web Development</h3>
                <h3>Digital Marketing</h3>
               </div>
               <div className="Heading-Container-footer2">
                <h3>Graphic Designer</h3>
                <h3>Mobile App development</h3>
                <h3>Search Engine Optimization</h3>
               </div>
               <div className="Heading-Container-footer3">
                <h3>Content Writting</h3>
                <h3>Wire framing</h3>
                <h3>UI/UX Design</h3>
               </div>
               <div className="Heading-Container-footer4">
                <h3>E-commerce Solution</h3>
                <h3>Brand Identity</h3>
                <h3>Logo design</h3>
               </div>
            </div>
            <div className="Navbar-Seo-Services">
              <div className="social-apps-navbar">
                 <div className="social-apps-navbar-heading">
                    <h2>Be Social</h2>
                 </div>
                 <div className="social-apps-footer">
                 <FontAwesomeIcon className='icons' icon={faYoutube} style={{ color: "#ffffff" }} />
                 <FontAwesomeIcon className='icons' icon={faTwitter} style={{ color: "#ffffff" }} />
                 <FontAwesomeIcon className='icons' icon={faInstagram} style={{ color: "#ffffff" }} />
                 <FontAwesomeIcon  className='icons' icon={faFacebook} style={{ color: "#ffffff" }} />
                 </div>
              </div>
              <div className="copyrights-credits">
              <div className="copyrights-left-aligned">
              <div className="copyrights">
                <h3>Copyrights and credits</h3>
                </div>
                <div className="copyright-text">
                   <p>Website copyright 2023 ProSales Tech LLC</p> 
                </div>
              </div>
              <div className="copyrights-right-aligned">
              <div className="logo-image-container">
      <img src={logoImage} alt=""  className='logo-image'/>
      </div>
              </div>
              </div>

            </div>
        </div>
    </>
  )
}

export default Footer