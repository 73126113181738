import {React} from 'react'
import './Contactus.css'
import Header from './Header'
import Footer from './Footer'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTwitter,faFacebook,faLinkedin} from '@fortawesome/free-brands-svg-icons'
const Contactus = () => {
   
      return(
    <>
    <Header/>
    <div className='contact-us-main-container'>
       <div className='contact-us-left-half-container'>
        <div className='contact-us-heading-container'>
            <h1>Let's work together!</h1>
        </div>
      
        <div className='contact-us-text-container'>
            <h3>Direct: <span className='contact-number'>484-877-7850</span></h3>
        </div>
        <div className='contact-email-text-container'>
            <h3>Email: <span className='email'>info@prosalestech.com</span></h3>
        </div>
        <div className='font-awesome-icons'>
                 <FontAwesomeIcon className='contact-us-icons' icon={faTwitter} style={{ color: "#3b5998" }} />
                 <FontAwesomeIcon className='contact-us-icons icon-1' icon={faLinkedin} style={{ color: "#3b5998" }} />
                 <FontAwesomeIcon  className='contact-us-icons icon-1' icon={faFacebook} style={{ color: "#3b5998" }} />
        </div>
        <div className='contact-us-uppertext-container'>
            <p>If you are an Owner, President, CEO, Sales & or a Marketing Professional looking for Leads, Lead Generation Services, Tech, Marketing, Call Centre Outsourcing or Consulting Services, 1 on 1 Individual Sales Mentor, Corporate Group Sales Coach if you & or team demand high performance results your welcome to send an Email, Call or Schedule a Zoom Meeting.</p>
        </div>
       </div>
       <div className='contact-us-right-half-container'>
      <form action="#" >
        <div className='name-container'>
        <input type='text' name='firstname' id='firstname' className='first-name' placeholder='First Name' required />
        <input type='text' name='lastname' id='lastname' className='last-name' placeholder='Last Name' required  />
        </div>
        <div className='same-input-fields'>
          <input name='phonenumber' type='tel' id='phonenumber'  className='contact-number-form' placeholder='Enter Number' />
          <input name='email' type='email' id='contactemail' className='contact-email-form' placeholder='Enter Email' />
        </div>
        <div className='text-area' >
          <textarea name='message' id='message' placeholder='How can we help?' className='text-area-field' ></textarea>
        </div>        
        <button type='submit' className='contact-form-submit-button'>Submit</button>
       </form>
       
      </div>
       </div>
     <Footer/>
    </>
  )
}

export default Contactus