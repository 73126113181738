import './App.css';
import Home from './components/Home';
import ServicesPage from './components/ServicesPage';
import NewServicesPage from './components/NewServicesPage';
import {BrowserRouter as Router,Routes,Route } from "react-router-dom";
import Contactus from './components/Contactus'
import AboutPage from './components/About'

function App() {
  return (
      <Router>
 <div className="App">
    <Routes>
    <Route path="/" element={<Home/>} />
     <Route path='/home' element={<Home/>}/>
     <Route path='/Services' element={<NewServicesPage/>}/>
     <Route path='/about' element={<AboutPage/>}/>
     <Route path='/contact' element={<Contactus/>}/>
    </Routes>
      </div>
      </Router>
  );
}

export default App;
