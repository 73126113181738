import React from 'react'
import './AboutuspageCard2.css'
import uniquepicturecard from '../images/aboutuscard2.webp'
const AboutuspageCar2 = () => {
  return (
    <div className='main-container-about-us-card'>
      
        <div className='about-us-page-right-half-container'>
             <div className='about-us-page-right-half-heading-container'>
              <h1>We engage in a process of continuous improvement to our outbound sales process</h1>
             </div>
             <div className='about-us-page-right-half-text-container'>
                <p>We connect our clients with their target market and inspire prospects to want to learn more about our client’s experience and capabilities. We do the “early-stage heavy lifting of the sales process” so that the sales teams can focus on working the deals that will grow the business.</p>
             </div>
        </div>
        <div className='about-us-page-left-half-container'>
          <div className='about-us-page-image'>
            <img src={uniquepicturecard}>
            </img>
          </div>
        </div>
    </div>
  )
}

export default AboutuspageCar2