import React from 'react'
import './Home.css'
import BannerVideo from './BannerVideo'
import DigitalMarketing from './DigitalMarketing'
import Articles from './Articles'
import Footer from './Footer'
import Header from './Header'
import Index from './Home - Slider/Index'
const Home = () => {
  return (
    <div>
        <Header/>
        <BannerVideo/>
        <Index/>
        <Articles/>
        <DigitalMarketing/>
        <Footer/>
    </div>
  )
}

export default Home