import React from 'react'
import './ServicePageIntro.css'
const ServicePageIntro = () => {
  return (
    <div>
        <div className='service-page-intro-main'>
            <div className='service-page-intro-text-1'>
                <h4>TACKLING YOUR UNIQUE BUSINESS CHALLENGES</h4>
            </div>
            <div className='service-page-intro-heading-1'>
                <h1>More Than a Service: We're Your Strategic Partner</h1>
            </div>
            <div className='service-page-intro-heading-text'>
              <p>Achieving growth is challenging, and maintaining sustainable growth is even more demanding. While one-size-fits-all processes may work for some, we understand that each business faces its own set of distinctive challenges. That's why we collaborate with you to develop customized processes that consistently generate a stream of qualified leads and sales meetings. Discover more about our tailored solutions below:</p>
            </div>
        </div>
    </div>
  )
}

export default ServicePageIntro